export const SET_APPOINTMENTS = "setAppointments";

export const PURGE_OPERATOR_AUTH = "purgeOperator";
export const SET_OPERATOR_AUTH = "setOperator";

export const SET_APPOINTMENT = "setAppointment";
export const SET_APPOINTMENT_SEARCH_PARAMS = "setAppointmentSearchParams";
export const SET_APPOINTMENT_JWT = "setAppointmentJwt";
export const SET_CLIENT_IP = "setClientIp";
export const SET_TENANT_ID = "setTenantId";
export const SET_TENANT_LAYOUT = "setTenantLayout";

export const RESET_STATE = "resetModuleState";
