import { OperatorService } from "@/common/api.service";
import { isCotoHostname } from "@/common/utils";
import JwtService from "@/common/jwt.service";

import { FRONTOFFICE_PUBLISHED, FRONTOFFICE_RESERVED } from "@/common/config";

import {
  OPERATOR_LOGIN,
  OPERATOR_LOGOUT,
  OPERATOR_CHECK_AUTH
} from "./actions.type";
import { SET_OPERATOR_AUTH, PURGE_OPERATOR_AUTH } from "./mutations.type";

const state = {
  frontOffices: [],
  operator: {},
  ifAdminAuthenticated: false,
  ifOperatorAuthenticated: false
};

const getters = {
  currentFrontOffices(state) {
    return state.frontOffices;
  },
  currentOperator(state) {
    return state.operator;
  },
  ifOperatorAuthenticated(state) {
    return state.ifOperatorAuthenticated;
  }
};

const actions = {
  // Forse la parte di Admin la si sposta su AppSched
  /*
  async [ADMIN_LOGIN](context, credentials) {
    return await AdminService.login(credentials).then(
      response => {
        context.commit(SET_ADMIN_AUTH, response.data);
        return Promise.resolve(response);
      },
      error => {
        return Promise.reject(error);
      }
    );
  },
  [ADMIN_LOGOUT](context) {
    context.commit(PURGE_ADMIN_AUTH);
  },
  */
  async [OPERATOR_LOGIN](context, credentials) {
    // cfr https://bezkoder.com/jwt-vue-vuex-authentication/
    return await OperatorService.login(credentials).then(
      response => {
        JwtService.saveToken(JSON.stringify(response.data));
        context.commit(SET_OPERATOR_AUTH, response.data);
        return Promise.resolve(response);
      },
      error => {
        return Promise.reject(error);
      }
    );
  },
  async [OPERATOR_CHECK_AUTH](context) {
    if (isCotoHostname()) {
      if (JwtService.getToken()) {
        context.commit(SET_OPERATOR_AUTH, JSON.parse(JwtService.getToken()));
      } else {
        context.commit(PURGE_OPERATOR_AUTH);
      }
      return;
    }
    const appschedJwt = JwtService.getVideAuthCookie();
    if (appschedJwt == null) {
      context.commit(PURGE_OPERATOR_AUTH);
      return;
    }
    return await OperatorService.decodeToken(appschedJwt).then(
      response => {
        context.commit(SET_OPERATOR_AUTH, response.data);
        return Promise.resolve(response);
      },
      error => {
        context.commit(PURGE_OPERATOR_AUTH);
        return Promise.reject(error);
      }
    );
  },
  [OPERATOR_LOGOUT](context) {
    if (isCotoHostname()) {
      JwtService.destroyToken();
    } else {
      const appschedJwt = JwtService.getVideAuthCookie();
      if (appschedJwt != null) {
        JwtService.destroyVideAuthCookie();
      }
    }
    context.commit(PURGE_OPERATOR_AUTH);
  }
};

const mutations = {
  // Forse la parte di Admin la si sposta su AppSched
  /*
  [SET_ADMIN_AUTH](state, admin) {
    state.ifAdminAuthenticated = true;
    state.admin = admin;
  },
  [PURGE_ADMIN_AUTH](state) {
    state.ifAdminAuthenticated = false;
    state.admin = {};
  },
  */
  [SET_OPERATOR_AUTH](state, data) {
    state.ifOperatorAuthenticated = true;
    state.operator = data.operator;
    if (isCotoHostname()) {
      state.frontOffices = data.frontOffices.filter(
        p =>
          p.status == FRONTOFFICE_PUBLISHED || p.status == FRONTOFFICE_RESERVED
      );
    }
  },
  [PURGE_OPERATOR_AUTH](state) {
    state.ifOperatorAuthenticated = false;
    state.operator = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
