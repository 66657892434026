import { VIDE_API_URL } from "@/common/config";
import { isCotoHostname } from "@/common/utils";
import { axiosPublicInstance } from "@/plugins/axios";
import store from "@/store";
import axios from "axios";

const { v4: uuidv4 } = require("uuid");

const ApiService = {
  query(resource, params) {
    params.data = "";
    return axiosPublicInstance.get(`${resource}`, params);
  },

  get(resource, slug = "", params) {
    return axiosPublicInstance.get(`${resource}/${slug}`, params);
  },

  post(resource, params, config) {
    return axiosPublicInstance.post(`${resource}`, params, config);
  },

  put(resource, slug = "", params, config) {
    return axiosPublicInstance.put(`${resource}/${slug}`, params, config);
  },

  delete(resource, params) {
    return axiosPublicInstance.delete(`${resource}`, params);
  },

  attach(resource, slug = "", formData) {
    const mfdHeader = {
      "Content-Type": "multipart/form-data",
      "X-Request-ID": uuidv4(),
      "X-Forwarded-For": store.getters.clientIp
    };
    if (!isCotoHostname()) {
      mfdHeader["X-Tenant-Id"] = store.getters.tenantId;
    }
    return axios.create().post(`${VIDE_API_URL}${resource}/${slug}`, formData, {
      headers: mfdHeader,
      params: formData
    });
  }
};

export function getStandardHeader() {
  const stdHeader = {
    "Content-Type": "application/json",
    "X-Request-ID": uuidv4(),
    "X-Forwarded-For": store.getters.clientIp
  };
  if (!isCotoHostname()) {
    stdHeader["X-Tenant-Id"] = store.getters.tenantId;
  }
  return stdHeader;
}

export default ApiService;

export const AppointmentService = {
  listAppointments(params) {
    return ApiService.query("appointments", {
      headers: getStandardHeader(),
      params: params,
      data: ""
    });
  },
  getVirtualDeskId() {
    return ApiService.query("virtual-desk", {
      headers: getStandardHeader(),
      data: ""
    });
  },
  createAppointment(params) {
    return ApiService.post("appointments", params, {
      headers: getStandardHeader()
    });
  },
  updateAppointment(slug, params) {
    return ApiService.put("appointments", slug, params, {
      headers: getStandardHeader()
    });
  }
};

export const AttachmentService = {
  upload(idAppointment, params) {
    return ApiService.attach("attachment", idAppointment, params);
  },
  listAttachments(slug) {
    return ApiService.query(`attachment/${slug}`, {
      headers: getStandardHeader(),
      data: ""
    });
  },
  download(appointment_id, attachment_id) {
    const dwnHeader = {
      "X-Request-ID": uuidv4(),
      "X-Forwarded-For": store.getters.clientIp
    };
    if (!isCotoHostname()) {
      dwnHeader["X-Tenant-Id"] = store.getters.tenantId;
    }
    return ApiService.query(`attachment/${appointment_id}/${attachment_id}`, {
      responseType: "arraybuffer",
      headers: dwnHeader
    });
  },
  delete(appointment_id, attachment_id) {
    return ApiService.delete(`attachment/${appointment_id}/${attachment_id}`, {
      headers: getStandardHeader(),
      data: ""
    });
  }
};

export const GlobalService = {
  carousel() {
    return ApiService.query("carousel", {
      headers: getStandardHeader()
    });
  },
  layout() {
    return ApiService.query("layout", {
      headers: getStandardHeader()
    });
  },
  tenant(hostname) {
    return ApiService.get("tenant", hostname, {
      headers: {
        "Content-Type": "application/json",
        "X-Request-ID": uuidv4(),
        "X-Forwarded-For": store.getters.clientIp
      },
      data: ""
    });
  }
};

export const OperatorService = {
  decodeToken(token) {
    const header = getStandardHeader();
    header["X-Token-JWT"] = token;
    return ApiService.query("operator/decodeToken", {
      headers: header,
      data: ""
    });
  },
  login(params) {
    return ApiService.post("operator", params, {
      headers: getStandardHeader()
    });
  }
};
