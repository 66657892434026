import { getStandardHeader } from "@/common/api.service";
import { axiosPublicInstance } from "@/plugins/axios";
import { APPOINTMENTS_FETCH } from "./actions.type";
import { SET_APPOINTMENTS } from "./mutations.type";

const initialState = {
  appointments: [],
  appointmentsCount: 0
};

export const state = { ...initialState };

export const actions = {
  async [APPOINTMENTS_FETCH](context, params) {
    axiosPublicInstance
      .get("appointments", {
        headers: getStandardHeader(),
        params: params,
        data: ""
      })
      .then(response => {
        context.commit(SET_APPOINTMENTS, response);
      });
  }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
export const mutations = {
  [SET_APPOINTMENTS](state, response) {
    if (response.status == 204) {
      // 204 - "No Content"
      state.appointments = [];
      state.appointmentsCount = 0;
    } else {
      state.appointments = response.data;
      state.appointmentsCount = Number(response.headers.total_elements);
    }
  }
};

const getters = {
  appointments(state) {
    return state.appointments;
  },
  appointmentsCount(state) {
    return state.appointmentsCount;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
