// @see https://30secondsofcode.org/type#isnil
export const isNil = val => val === undefined || val === null;

export const isEmptyHash = val =>
  Object.keys(val).length === 0 && val.constructor === Object;

// @see https://30secondsofcode.org/type#isempty
export const isEmpty = val => val == null || !(Object.keys(val) || val).length;

// @see https://30secondsofcode.org/type#isobject
export const isObject = obj => obj === Object(obj);

export function getHostname() {
  let hostname = window.location.hostname;
  if (hostname == "localhost")
    hostname = "dev-torino-vide.preprod.nivolapiemonte.it";
  return hostname;
}

export function isCotoHostname() {
  return (
    window.location.hostname == "localhost" ||
    window.location.hostname == "dev-torino-vide.preprod.nivolapiemonte.it" ||
    window.location.hostname == "tst-torino-vide.preprod.nivolapiemonte.it" ||
    window.location.hostname == "torino.vide.csi.it"
  );
}
