import Vue from "vue";
import VueCookies from "vue-cookies";
import { VIDE_AUTH_COOKIE } from "@/common/config";

Vue.use(VueCookies);

const ID_TOKEN_KEY = "ViDeOperatorToken";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const getVideAuthCookie = () => {
  if (!Vue.$cookies.isKey(VIDE_AUTH_COOKIE)) return null;
  return Vue.$cookies.get(VIDE_AUTH_COOKIE);
};

export const destroyVideAuthCookie = () => {
  Vue.$cookies.remove(VIDE_AUTH_COOKIE);
};

export default {
  getToken,
  saveToken,
  destroyToken,
  destroyVideAuthCookie,
  getVideAuthCookie
};
