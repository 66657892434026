import { APPSCHED_LOGIN_URL } from "@/common/config";
import { isCotoHostname } from "@/common/utils";
import router from "@/router";
import store from "@/store";
import { OPERATOR_LOGOUT } from "@/store/actions.type";

export const login = () => {
  if (isCotoHostname()) {
    router.push({ path: "/operator/login" });
  } else {
    window.location = APPSCHED_LOGIN_URL;
  }
};

export const logout = () => {
  store.dispatch(OPERATOR_LOGOUT).then(() => {
    if (isCotoHostname()) {
      router.push({ name: "operator-login" });
    } else {
      window.location = APPSCHED_LOGIN_URL;
    }
  });
};
